import caramel_jag from "../images/snakes/carmel_jag_2020_600.jpg";
import donk_picky from "../images/snakes/donk_picky_600.jpg";
import jungle3 from "../images/snakes/postings/Jungle2020_600.jpg";
import bredli from "../images/snakes/stripedBredliHatchling_600.jpg";
import ben2 from "../images/people/ben2_600.jpg";
import river from "../images/snakes/snake_river_600.jpg";

const images = [river, jungle3, bredli, caramel_jag, donk_picky, ben2];

export default images;
